<template>
	<div class="grid">
		<Dialog header="Edit password" v-model:visible="displayEditPassword" :style="{width: '350px'}" :modal="true">
			<div class="flex formgrid align-items-center justify-content-center">
				<span class="field p-fluid">
					<label for="newpassword">Password</label>
					<InputText v-model="newpassword" id="newpassword" type="password"/>
				</span>
			</div>
			<template #footer>
				<Button label="Cancel" icon="pi pi-times" @click="displayEditPassword = !displayEditPassword" class="p-button-text"/>
				<Button label="Change Password" icon="pi pi-check" @click="displayEditContinue" class="p-button-text" autofocus />
			</template>
		</Dialog>
		<div class="col-12">
			<div class="card">
				<h5>User List</h5>
				<DataTable :value="customer1" :paginator="true" class="p-datatable-gridlines" :rows="10" dataKey="id" :rowHover="true" 
							v-model:filters="filters1" :loading="loading1" :filters="filters1" responsiveLayout="scroll"
							:globalFilterFields="['dname','uname']" >
					
					<template #header>
                        <div class="flex justify-content-between flex-column sm:flex-row">
                            <Button type="button" icon="pi pi-filter-slash" label="Clear" class="p-button-outlined mb-2" @click="clearFilter1()"/>
                            <span class="p-input-icon-left mb-2">
                                <i class="pi pi-search" />
                                <InputText v-model="filters1['global'].value" placeholder="Keyword Search" style="width: 100%"/>
                            </span>
                        </div>
                    </template>
                    <template #empty>
                        No users found.
                    </template>
                    <template #loading>
                        Loading users data. Please wait.
                    </template>
					<Column field="dname" header="Display Name" style="min-width:12rem">
                        <template #body="slotProps">
                            {{slotProps.data.dname}}
                        </template>
                    </Column>
					<Column field="uname" header="Username" style="min-width:12rem">
                        <template #body="slotProps">
                            {{slotProps.data.uname}}
                        </template>
                    </Column>
                    <Column field="passwd" header="Password" style="min-width:12rem">
                        <template #body="slotProps">
                            {{slotProps.data.passwd}}
                        </template>
                    </Column>
					<Column field="id" style="min-width:12rem">
                        <template #body="slotProps">
							<Button class="p-button-rounded p-button-text p-button-info mr-2" icon="pi pi-pencil" @click="userEdit($event, slotProps.data.id)" v-tooltip="'Edit Password'" />
                            <Button class="p-button-rounded p-button-text p-button-danger" icon="pi pi-trash" @click="userDelete($event, slotProps.data.id)" v-tooltip="'Remove User'" />
                        </template>
                    </Column>
				</DataTable>
			</div>
		</div>
	</div>
</template>

<script>
	import {FilterMatchMode,FilterOperator} from 'primevue/api';
	export default {
		data() {
			return {
				customer1: null,
				filters1: null,
				loading1: true,
				idFrozen: false,
				products: null,
				expandedRows: [],
				displayEditPassword: false,
				newpassword: '',
				newpassuid: ''
			}
		},
		customerService: null,
		productService: null,
		created() {
			let that = this
			this.axios.post('/user', {
				authToken: this.$store.state.sessionUnique
			}).then(function (response) {
				that.customer1 = response.data.users
				that.loading1 = false;
			});
			this.initFilters1();
		},
		mounted() {},
		methods: {
			initFilters1() {
				this.filters1 = {
					'global': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
					'dname': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
					'uname': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]}
				}
			},
			clearFilter1() {
				this.initFilters1();
			},
			expandAll() {
				this.expandedRows = this.products.filter(p => p.id);
			},
			collapseAll() {
				this.expandedRows = null;
			},
			userDelete(event, uid) {
				let that = this
				this.$confirm.require({
					target: event.currentTarget,
					message: 'Are you sure you want to proceed?',
					icon: 'pi pi-exclamation-triangle',
					accept: () => {
						this.axios.delete('/user/'+uid, {
							authToken: this.$store.state.sessionUnique
						}).then(function (response) {
							that.$toast.add({severity:'success', summary:'Confirmed', detail:'You have removed the account.', life: 3000, function () { that.$router.go() }});
							that.customer1 = response.data.users
						});
					},
					reject: () => {
						this.$toast.add({severity:'info', summary:'Rejected', detail:'User deletion cancelled!', life: 3000});
					}
				});
			},
			displayEditContinue() {
				let that = this
				if(this.newpassword.length < 6) {
					this.$toast.add({severity:'error', summary:'Oops', detail:'Password must be minimum 6 character long!', life: 3000});
					return
				}
				this.axios.patch('/user/'+this.newpassuid, { password: this.newpassword, authToken: this.$store.state.sessionUnique }).then(function (response) {
					that.$toast.add({severity:'success', summary:'Confirmed', detail:'You have edited the password.', life: 3000, function () { that.$router.go() }});
					that.customer1 = response.data.users
					that.displayEditPassword = !that.displayEditPassword
					that.newpassuid = ''
					that.newpassword = ''
				});
			},
			userEdit(event, uid) {
				this.displayEditPassword = !this.displayEditPassword
				this.newpassuid = uid
			}
		}
	}
</script>

<style scoped lang="scss">
@import '../assets/demo/badges.scss';

::v-deep(.p-datatable-frozen-tbody) {
    font-weight: bold;
}

::v-deep(.p-datatable-scrollable .p-frozen-column) {
    font-weight: bold;
}
</style>
